import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

import { CloseIcon } from '../components/Navbar/CloseIcon'
import RegionDetailsPopup from './RegionDetailsPopup'

export default function RegionInformationPopup({
  data,
  handleClose = () => null,
  closeRegionInformation,
}) {
  const [showRegionDiv, setShowRegionDiv] = useState(false)
  const [regionDetailsPopup, setRegionDetailsPopup] = useState(false)

  const submitRegion = (region) => {
    const regionData = {
      region,
    }
    App.Request.post(`/countries/change_region`, regionData)
      .then(() => {
        Cookies.set('region_confirmed', 1, { expires: 30 })
        window.location.reload()
        closeRegionInformation()
      })
      .catch((error) => {
        console.error('Error changing region:', error)
      })
  }

  const changeRegion = () => {
    setShowRegionDiv(true)
  }

  const goBack = () => {
    setShowRegionDiv(false)
  }

  const regionDetailsHandleClose = () => {
    setRegionDetailsPopup(false)
  }

  const Quadrant = ({ src, alt, id, region, onClick }) => (
    <div
      className="quadrant d-flex justify-content-center"
      id={id}
      onClick={onClick}
    >
      <img src={src} alt={alt} />
      <div className="region-option-country">
        {region.region}
        <span className="d-block small">{I18n.t('activerecord.attributes.car.currency')}: {region.currency}</span>
      </div>
    </div>
  )

  return (
    <>
      {Cookies.get('region_confirmed') && (
        <RegionDetailsPopup
          regionDetailsPopup={regionDetailsPopup}
          handleClose={regionDetailsHandleClose}
          region={data.session_region}
        />
      )}
      {!Cookies.get('region_confirmed') && (
        <div className="position-fixed bottom-0 desktop-popup-style">
          <div className="popup-container">
            <div id="region-popup-close-icon" className="close-popup" onClick={handleClose}>
              <CloseIcon />
            </div>
            {showRegionDiv && (
              <>
                <div id="region-popup-back-button" className="go-back" onClick={goBack}>
                  <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                </div>
                <div className="region-options-grid">
                  {data.other_regions.map((region, index) => (
                    <Quadrant
                      key={index}
                      src={region.image_url} // assuming this is the correct field
                      alt={`Option ${index + 1}`}
                      id={`quadrant${index + 1}`}
                      region={region}
                      onClick={() => submitRegion(region)}
                    />
                  ))}
                </div>
              </>
            )}
            {!showRegionDiv && (
              <>
                <img src={data.session_region.image_url} alt="Region Popup" className="popup-image" />
                <div className="region-popup-header m-3">
                  <h2>{I18n.t('popup.header')}</h2>
                  <strong>{data.session_region.region}</strong>
                </div>
                <div className='region-currency'>
                  <span>{I18n.t('activerecord.attributes.car.currency')}: {data.session_region.currency}</span>
                </div>
                <div className="region-popup-footer">
                  <button
                    id="region-popup-continue-button"
                    className="continue-button btn btn-primary text-black rounded-8 fixed-height-button"
                    onClick={() => submitRegion(data.session_region)}
                  >
                    {I18n.t('popup.button.continue')}
                  </button>
                  <button
                    id="region-popup-change-region-button"
                    className="change-region-button btn-primary text-black rounded-8 fixed-height-button"
                    onClick={changeRegion}
                  >
                    {I18n.t('popup.button.change_region')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
