import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import RegionInformationPopup from './RegionInformationPopup'

const RegionInformation = () => {
  const [isRegionInformationOpen, setIsRegionInformationOpen] = useState(false)
  const [isRegionDetailsOpen, setIsRegionDetailsOpen] = useState(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const delay = typeof App.State.carId !== 'undefined' ? 10000 : 0

  useEffect(() => {
    setIsLoading(true)
    fetchData()
    if (Cookies.get('region_confirmed') && !Cookies.get('region_details')) {
      setIsRegionDetailsOpen(true)
    }

    setTimeout(() => {
      if (!Cookies.get('reg_info_popup')) {
        setIsRegionInformationOpen(true)
      }
    }, delay)
  }, [])

  const handleClose = () => {
    setIsRegionInformationOpen(false)
    Cookies.set('reg_info_popup', 1)
  }

  const fetchData = async () => {
    try {
      const res = await App.Request.get(
        '/countries/region_countries_info.json',
        {
          params: { countryCode: App.State.requestCountry },
        }
      )
      setData(res.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }

  return (
    <>
      {!isLoading && (isRegionInformationOpen || isRegionDetailsOpen) && (
        <>
          <RegionInformationPopup
            data={data}
            isRegionInformationOpen={isRegionInformationOpen}
            handleClose={handleClose}
            closeRegionInformation={() => setIsRegionInformationOpen(false)}
          />
        </>
      )}
    </>
  )
}

export default RegionInformation
