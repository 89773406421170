import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

export default function RegionDetailsPopup({
  handleClose = () => null,
}) {
  const [showRegionDetails, setShowRegionDetails] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (!Cookies.get('shown_region_details')) {
      fetchDetails()
    }
  }, [])

  const fetchDetails = async () => {
    try {
      const res = await App.Request.get(
        '/countries/region_details.json',
        {
        params: { slug: Cookies.get('region') },
      })
      setData(res.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }

  const setRegionCookie = () => {
    Cookies.set('shown_region_details', 1, { expires: 30 })
  }

  const handleCloses = () => {
    setShowRegionDetails(false)
  }

  return (
    <>
      {!isLoading && !Cookies.get('shown_region_details') && showRegionDetails && (
        <div className="position-fixed bottom-0 desktop-popup-style">
          <div className="popup-container">
            <img src={data.image_url} alt="Region Popup" className="popup-image" />
            <div className="region-popup-header m-3">
              <strong>{data.region}</strong>
            </div>
            <div className="close-popup" onClick={handleClose}></div>
            <div className="region-currency lh-base">
              <div className="text-white">
                {I18n.t('popup.cars_in')} {data.region}: {data.cars_total}
              </div>
              <div className="text-white">
                {I18n.t('popup.dealers_in')} {data.region}: {data.dealers_total}
              </div>
            </div>
            <div className="region-popup-footer">
              <a
                id="region-popup-see-cars-in-region"
                target='_blank'
                href={data.url_path}
                className="cars-path-button btn btn-primary text-black rounded-8 fixed-height-button"
                onClick={() => {
                  setRegionCookie()
                  handleCloses()
                }}
              >
                {I18n.t('popup.see_cars_in')} {data.region}!
              </a>
              <button
                id="region-popup-close-details-region"
                className="close-button btn btn-primary text-black rounded-8 fixed-height-button"
                onClick={() => {
                  setRegionCookie()
                  handleCloses()
                }}
              >
                {I18n.t('close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
